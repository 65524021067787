import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import LanguageTranslationService from "components/languageTranslationService";

function SupportedLanguages() {
  const title = "Supported Languages - Tomedes";
  const description =
    "Avail now of highly professional, accurate, and fast translation with Tomedes' language translation services.";
  const keywords =
    "language translation services, language translation service, language translation";
  const slug = "/languages";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <LanguageTranslationService textColor="text-white" />
    </Layout>
  );
}
export default SupportedLanguages;
